import React from "react"
import { graphql } from "gatsby"

import FunnelHero from "../sections/funnel/FunnelHero"
import FunnelGrid from "../sections/funnel/FunnelGrid"
import { Seo } from "../components"
import { formatFunnelUri } from "../utils/format-funnel-uri"

const FunnelPage = ({ data, pageContext: {  kategorie, type } }) => {
  const pages = data.page.edges

  const title = kategorie
  const subtitle = `Alle ${type} im Überblick.`

  const uri = formatFunnelUri(type, undefined, kategorie)

  return (
    <>
      <Seo
        title={title}
        description={subtitle}
        // banner={banner}
        pathname={uri}
        article
      />
      <FunnelHero
        title={title}
        subtitle={subtitle}
        kategorie={kategorie}
        jahr={undefined}
        type={type}
      />
      <FunnelGrid data={pages} />
    </>
  )
}

export default FunnelPage

export const query = graphql`
  query ($kategorie: String!, $type: String!) {
    page: allWpPage(
      filter: {
        pagebuilder: {
          fixeElementeTop: {
            kategorie: { in: [$kategorie] }
            typ: { name: { eq: $type } }
          }
        }
      }
    ) {
      edges {
        node {
          title
          uri
          pagebuilder {
            fixeElementeTop {
              teaserText
              kategorie
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
